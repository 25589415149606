.sidebar {
  position: fixed;
  transition: nth($transitions, 6);
  top: 0;
  bottom : 0;
  transform: translateX(100%);
  left: initial;
  right: 0;
  width: 80%;
  background-color: #fff;
  padding: 3rem 4rem 15rem 4rem;
  z-index: 4;
  overflow: scroll;
  font-family: Roboto;
  &__form{
    margin-bottom: 5rem;
  }
  &__form-control {
    position: relative;
  }
  &__header{
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    font-family: Roboto;
    color: $pickled-bluewood;
  }
  &__actions {
    position : relative;
    font-size: 1.2rem;
    .sidebar__close {
      position: absolute;
      right: -3rem;
      top: -2rem;
      cursor: pointer;
      width: 1rem;
      height: 1rem;
      color: #3C4E64;
    }
  }
  &__warning {
    margin-bottom: 1.5rem;
    color: #6D7F92;
    h5 {
      font-size: 14px;
    }
    h4{
      font-size: 14px;
      margin-left: 28px;
      margin-bottom: 10px;
    }
    .notes {
      display: flex;
      justify-content: space-around;
      background: #FCFAF2;
       &_remark{
        font-size: 12px;
        padding: 15px;
        margin-left: 24px;
        margin-top: -10px;
       }
      &__item {
        text-align: center;
        padding: 2rem .5rem;
        position: relative;
        font-weight: normal;
        font-size: 12px;
        cursor: pointer;
        &-head {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #fff;
          position: absolute;
          top: -15px;
          left: 0;
          right: 0;
          margin: auto;
          font-size: 16px;
        }
      }
    }
  }
  &--opened {
    transition: 0.6s transform ease-in-out;
    transform: translateX(0);
  }
  .send-header {
    display: flex;
    margin: 0 0 2rem;
    align-items: center;
    span {
      margin: 0 1rem;
    }
    &__count {
      font-size: 22px;
      color: #4198d7;
      width: 45%;
      text-align: right;
    }
    &__target-type {
      font-size: 14px;
      color: #3C4E64;
      padding: 0 0 0 1rem;
      border-left: 1px solid #F1F1F1;
      padding-left: 2rem;
    }
    &__loader {
      transform: scale(0.5);
      margin-top: 10px;
    }
  }
}

.overlay {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: rgba(41, 57, 77, 0.4);
  z-index: 3;
  display: none;
  transition: 0.3s display ease-in-out;
  &.visible{
    display: block;
    z-index: 9;
  }
}

.tab_container {
  position: relative;
  border: 2px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  margin: 30px 16px;
}

.recipients-selection {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  margin: 30px 16px;

  &__title {
    position: absolute;
    top: -10px;
    left: 20px;
    background-color: #f8f9fa;
    padding: 0 10px;
    font-size: 16px;
    color: #333;
    font-weight: 500;

    // This creates a small gap in the border behind the text
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #f8f9fa;
      top: 50%;
      z-index: -1;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
}
